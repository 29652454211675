import React, { useEffect, useState } from "react";
import { ListPage } from "../ListPage";
import config from "../../config";
import * as API from "../../helpers/api";
import NProgress from "nprogress";
import { useLang } from "../../helpers/language";
import moment from "moment";
import { useMantineColorScheme } from "@mantine/core";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

export default function DebtList() {
	const { lang } = useLang();
	const { colorScheme } = useMantineColorScheme();
	const [clients, setClients] = useState([]);
	const [orders, setOrders] = useState([]);
	const [isBusy, setBusy] = useState(true);
	const [columns, setColumns] = useState([]);
	const [totalDebt, setTotalDebt] = useState(0);

	function setRefreshColumns() {
		return [
			{
				accessorFn: (row) => row.client?.name,
				permissionModel: "client",
				header: config.translate.client[lang],
				filterVariant: "select",
				mantineFilterSelectProps: {
					data: clients.map((client) => ({
						value: client._id,
						label: client.name,
					})),
				},
				size: 200,
				columnSizing: true,
				Cell: (
					RecievedObject,
					cell = RecievedObject.renderedCellValue,
					row = RecievedObject.row.original
				) => {
					return <Link to={`/client/${row.client?._id}`}>{row.client?.name}</Link>;
				},
			},
			{
				accessorFn: (row) => String(row.order?.ID),
				permissionModel: "order",
				header: config.translate.order[lang],
				filterVariant: "select",
				mantineFilterSelectProps: {
					data: orders.map((order) => ({
						value: order._id,
						label: String(order.ID),
					})),
				},
				size: 300,
				columnSizing: true,
				Cell: (
					RecievedObject,
					cell = RecievedObject.renderedCellValue,
					row = RecievedObject.row.original
				) => {
					return row.order?.ID;
				},
			},
			{
				accessorKey: "amount",
				permissionModel: "amount",
				header: config.translate.price[lang],
				size: 200,
				Cell: (
					RecievedObject,
					cell = RecievedObject.renderedCellValue,
					row = RecievedObject.row.original
				) => {
					return new Intl.NumberFormat("en-US").format(Math.round(row.amount));
				},
			},
			{
				accessorKey: "date",
				permissionModel: "date",
				header: config.translate.date[lang],
				enableClickToCopy: true,
				filterVariant: "date-range",
				size: 320,
				Cell: (
					RecievedObject,
					cell = RecievedObject.renderedCellValue,
					row = RecievedObject.row.original
				) => {
					let result = moment(row.date).format("DD-MM-YYYY");
					return result;
				},
			},
			{
				accessorKey: "ID",
				permissionModel: "ID",
				header: "ID",
				size: 200,
				enableClickToCopy: true,
			},
		];
	}

	useEffect(() => {
		NProgress.start();
		(async () => {
			let rawClients = await API.get(config.api.client);
			if (rawClients) setClients(rawClients);

			let rawOrders = await API.get(config.api.client);
			if (rawOrders) setOrders(rawOrders);

			let forExcelDebt = [];
			let forExcelRepayment = [];

			let allDebts = await API.get(config.api.debt, null, { date: -1 });
			let debtLocal = 0;
			if (allDebts) {
				forExcelDebt = [...allDebts.map((debt) => debt.amount)];
				allDebts.forEach((debt) => {
					debtLocal += debt.amount;
				});
			}

			let allDebtsRepayments = await API.get(config.api.debtRepayment, null, { date: -1 });

			let debtRepaymentLocal = 0;
			if (allDebtsRepayments) {
				forExcelRepayment = [
					...allDebtsRepayments.map((debtRepayment) => debtRepayment.amount),
				];

				allDebtsRepayments.forEach((debtRepayment) => {
					debtRepaymentLocal += debtRepayment.amount;
				});
			}
			setTotalDebt(debtLocal - debtRepaymentLocal);

			setColumns(setRefreshColumns());

			setBusy(false);
		})();
		// eslint-disable-next-line
	}, [isBusy]);

	useEffect(() => {
		setColumns(setRefreshColumns());
		// eslint-disable-next-line
	}, [lang, colorScheme]);

	if (!isBusy && columns.length > 0)
		return (
			<ListPage
				route={config.api.debt}
				sorting={{ date: -1 }}
				permissionModel={"debt"}
				uploadType={null}
				limit={null}
				customFiltersArrays={{
					clients: clients,
					orders: orders,
				}}
				populate={["client", "order"]}
				columns={columns}
				pageSize={25}
				header={[
					{
						title: `${config.translate.total[lang]} ${config.translate.debt[lang]}`,
						route: "debt-list",
						value: totalDebt,
						color: "red.6",
					},
				]}
			/>
		);
}
