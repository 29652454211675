import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import NProgress from "nprogress";
import moment from "moment";
import * as API from "../helpers/api";
import config from "../config";
import { useUser } from "../helpers/userContext";
import { useLang } from "../helpers/language";
import { DELETE } from "../helpers/CRUD";
import {
	MantineReactTable,
	useMantineReactTable,
	MRT_ToggleFiltersButton,
	MRT_BottomToolbar,
} from "mantine-react-table";
import {
	CloseIcon,
	Button,
	Flex,
	Menu,
	Text,
	Card,
	Paper,
	useMantineTheme,
	AspectRatio,
	Overlay,
	Grid,
	Divider,
} from "@mantine/core";
import { IconEdit } from "@tabler/icons-react";
import { Link } from "react-router-dom";
import ErrorPage from "../helpers/ErrorPage";
import { BarChart } from "@mantine/charts";

function convertToGMT0(dateString) {
	// Create a Date object from the input date string
	const originalDate = new Date(dateString);
	// Get the local time components
	const year = originalDate.getUTCFullYear();
	const month = originalDate.getUTCMonth();
	const date = originalDate.getUTCDate();
	const hours = originalDate.getUTCHours();
	const minutes = originalDate.getUTCMinutes();
	const seconds = originalDate.getUTCSeconds();
	function getUserGMTOffset() {
		return hours;
	}
	let myGMTZone = getUserGMTOffset();
	// Create a new Date object in GMT with the same local time components
	const gmtDate = new Date(Date.UTC(year, month, date, hours + myGMTZone, minutes, seconds));
	return gmtDate.toUTCString();
}

function calculateBirthdate(age) {
	const currentDate = moment();
	// get the birthdate in correction of one day
	const birthDate = currentDate.subtract(age, "years").format("DD-MM-YYYY");
	return new Date(birthDate);
}

let FirstRender = false;
let SecondRender = false;

let formattedNumber = (number) =>
	new Intl.NumberFormat("en-US", {
		minimumFractionDigits: 2,
		maximumFractionDigits: 2,
	}).format(number);

export function ListPage({
	route,
	query,
	sorting,
	pageIndex = 0,
	pageSize = 10,
	permissionModel,
	uploadType,
	limit = 10,
	skip = 0,
	perPageHeader,
	populate,
	columns,
	header,
	editPage,
	addPage,
	clientAnalytics,
	productAnalytics,
	renderDetailPanel = null,
	pageTitle,
	noAdd,
	defaultColumn = {
		maxSize: 1000,
		minSize: 40,
		size: 180, //default size is usually 180
	},
	showSkeletons = true,
	enableColumnActions = false,
	enableEditing = false,
	enableColumnFilterModes = false,
	enableColumnOrdering = false,
	enableFacetedValues = false,
	enableGrouping = false,
	enablePinning = false,
	enableRowSelection = false,
	enableRowActions = true,
	enableSorting = false,
	enableColumnFilters = true,
	enableDensityToggle = false,
	mantineSearchTextInputProps = null,
	enableColumnResizing = false,
	enableStickyHeader = false,
	enableStickyFooter = false,
	mantinePaginationProps = {
		radius: "sm",
		size: "sm",
	},
	paginationDisplayMode = "pages",
	positionToolbarAlertBanner = "bottom",
	enableClickToCopy = false,
	initialState = {
		sorting: [],
		filters: [],
		grouping: [],
		pinnedColumns: [],
		hiddenColumns: [],
		selectedRows: [],
		searchQuery: "",
		showColumnFilters: true,
		showGlobalFilter: false,
	},
	customFiltersArrays,
}) {
	let history = useHistory();
	const { user } = useUser();
	let CRUD = {
		create: false,
		read: false,
		update: false,
		delete: false,
	};
	const [footer, setLocalHeader] = useState(header);
	const [apiData, setApiData] = useState([]);
	const location = useLocation();
	const { lang } = useLang();
	const [buttonLoading, setButtonLoading] = useState(false);
	const colorScheme = useMantineTheme();
	//data and fetching state
	const [visible, setVisible] = useState(true);
	const [isError, setIsError] = useState(false);
	let formatedLocation = location.search
		.replace("?", "")
		.split("&")
		.reduce((a, v) => ({ ...a, [v.split("=")[0]]: v.split("=")[1] }), {});
	const [CollectionCount, setCollectionCount] = useState(0);
	const [isLoading] = useState(false);
	const [isBusy, setBusy] = useState(false);
	//table state
	const [ColumnFilters, setColumnFilters] = useState([]);
	const [GlobalFilter, setGlobalFilter] = useState("");
	const [Sorting, setSorting] = useState([]);
	const [pagination, setPagination] = useState({
		pageIndex: Number(
			pageIndex ||
				pageIndex === 0 ||
				(formatedLocation.pageIndex && formatedLocation.pageIndex)
		),
		pageSize: Number(pageSize || (formatedLocation.pageSize && formatedLocation.pageSize)), //customize the default page size
	});

	user.role?.permissions?.forEach((permission) => {
		if (user?.ananunaki) {
			CRUD = {
				create: true,
				read: true,
				update: true,
				delete: true,
			};
		}
		if (permission.name === permissionModel) {
			CRUD.read = permission.crud.read;
			CRUD.create = permission.crud.create;
			CRUD.update = permission.crud.update;
			CRUD.delete = permission.crud.delete;
		}
	});

	const addNew = async () => {
		setButtonLoading(true);
		let result;
		let specificPageRoute = "" + route;
		try {
			result = await API.post(route, { temporary: true });
			history.push(`/${specificPageRoute}/${result._id}?temporary=true`);
			setButtonLoading(false);
		} catch (error) {
			console.error(error);
			setButtonLoading(false);
		}
	};

	let tempColumns = [];

	columns = columns.forEach((column) => {
		if (user?.ananunaki) {
			tempColumns.push(column);
		} else {
			user.role?.permissions?.forEach((permission) => {
				if (permission.name === permissionModel) {
					permission.inputs.forEach((input) => {
						if (column.permissionModel === input.name && input.read) {
							tempColumns.push(column);
						}
					});
				}
			});
		}
	});

	columns = tempColumns;

	const table = useMantineReactTable({
		defaultColumn,
		layoutMode: "grid-no-grow",
		columnResizeMode: "onChange",
		columns: columns ? columns : [],
		data: apiData ? apiData : [],
		enableColumnFilterModes,
		enableColumnOrdering,
		enableFacetedValues,
		enableDensityToggle,
		enableGrouping,
		enableColumnFilters,
		enableColumnActions,
		enablePinning,
		enableRowSelection,
		columnVirtualizerProps: {
			overscan: 5, //adjust the number of columns that are rendered to the left and right of the visible area of the table
			estimateSize: () => 40, //if your columns are wider or , try tweaking this value to make scrollbar size more accurate
		},
		rowVirtualizerProps: {
			overscan: 10, //adjust the number or rows that are rendered above and below the visible area of the table
			estimateSize: () => 10, //if your rows are taller than normal, try tweaking this value to make scrollbar size more accurate
		},
		enableRowActions,
		initialState: {
			...initialState,
			density: "xs",
			showGlobalFilter: true,
			sorting: Sorting,
		},
		paginationDisplayMode,
		positionToolbarAlertBanner,
		mantinePaginationProps,
		enableClickToCopy,
		mantineSearchTextInputProps,
		renderDetailPanel,
		enableColumnResizing,
		enableStickyHeader,
		enableStickyFooter,
		enableEditing,
		enableSorting,
		manualPagination: true,
		manualFiltering: true,
		manualSorting: false,
		onColumnFiltersChange: setColumnFilters,
		onGlobalFilterChange: setGlobalFilter,
		onSortingChange: setSorting,
		showSkeletons,
		mantineToolbarAlertBannerProps: isError
			? { color: "red", children: "Error loading data" }
			: undefined,
		rowCount: CollectionCount,
		onPaginationChange: (props) => {
			let localPagination = {
				pageIndex: Number(
					pageIndex ||
						pageIndex === 0 ||
						(formatedLocation.pageIndex && formatedLocation.pageIndex)
				),
				pageSize: Number(
					pageSize || (formatedLocation.pageSize && formatedLocation.pageSize)
				),
			};
			if (props.pageIndex !== 0) {
				if (FirstRender) SecondRender = true;
				FirstRender = true;
				setPagination(props);
			} else if (props.pageIndex === 0 && localPagination.pageIndex > 0) {
				if (FirstRender && SecondRender) {
					if (FirstRender) SecondRender = true;
					FirstRender = true;
					setPagination({
						...props,
						pageIndex: 0,
					});
				} else {
					if (FirstRender) SecondRender = true;
					FirstRender = true;
					setPagination({
						...props,
						pageIndex: localPagination.pageIndex,
					});
				}
			} else {
				if (FirstRender) SecondRender = true;
				FirstRender = true;
				setPagination({ ...props });
			}
		},
		state: {
			ColumnFilters,
			GlobalFilter,
			isLoading,
			pagination,
			showAlertBanner: isError,
		},
		renderRowActionMenuItems: ({ row }) => {
			return (
				<>
					{(user.ananunaki || CRUD.update) && (
						<Menu.Item>
							<Link to={`/${route}${editPage ? editPage : ""}/${row?.original?._id}`}>
								<Button
									leftSection={
										<IconEdit
											size={14}
											color={colorScheme === "dark" ? "black" : "white"}
										/>
									}
									style={{
										width: "100%",
										display: "flex",
										justifyContent: "space-between",
									}}
									variant="light"
								>
									{config.translate.edit[lang]}
								</Button>
							</Link>
						</Menu.Item>
					)}
					{(user.ananunaki || CRUD.delete) && (
						<Menu.Item>
							<Button
								color="red"
								leftSection={
									<CloseIcon
										size={14}
										color={colorScheme === "dark" ? "black" : "white"}
									/>
								}
								variant="light"
								style={{
									width: "100%",
									display: "flex",
									justifyContent: "space-between",
								}}
								onClick={() => {
									DELETE({
										route,
										id: row?.original?._id,
										// history,
										lang,
										setBusy,
										isBusy,
									});
								}}
							>
								{config.translate.remove[lang]}
							</Button>
						</Menu.Item>
					)}
				</>
			);
		},
		renderTopToolbar: ({ table }) => {
			return (
				<Flex p="md" justify="space-between">
					<Flex gap="xs">
						{(user.ananunaki || CRUD.create) && !noAdd && (
							<Button
								color="green"
								loading={buttonLoading}
								onClick={addNew}
								variant="filled"
							>
								{config.translate.add[lang]}
							</Button>
						)}
					</Flex>
					{/*  header */}
					{!perPageHeader && header && header.length > 0 && (
						<Flex
							gap="xs"
							onClick={() => setVisible((v) => !v)}
							style={{
								cursor: "pointer",
								userSelect: "none",
								boxShadow: "0 0 2px rgba(0, 0, 0, 0.1)",
								borderRadius: "5px",
								padding: "5px",
								overflow: "hidden",
							}}
							pos="relative"
						>
							{header.map((item, index) => {
								return (
									<Card key={index}>
										<Text>{item.title}</Text>
										<Text c={item.color} fw={"bold"}>
											{formattedNumber(item.value)}
										</Text>
									</Card>
								);
							})}
							{visible && <Overlay color="#fff" blur={20} backgroundOpacity={0.75} />}
						</Flex>
					)}

					{perPageHeader && (
						<>
							{footer && footer.length > 0 && (
								<Flex
									gap="xs"
									onClick={() => setVisible((v) => !v)}
									style={{
										cursor: "pointer",
										userSelect: "none",
										boxShadow: "0 0 2px rgba(0, 0, 0, 0.1)",
										borderRadius: "5px",
										padding: "5px",
										overflow: "hidden",
									}}
									pos="relative"
								>
									{footer.map((item, index) => {
										return (
											<Card key={index}>
												<Text>{item.title}</Text>
												<Text c={item.color} fw={"bold"}>
													{formattedNumber(item.value)}
												</Text>
											</Card>
										);
									})}
									{visible && (
										<Overlay color="#fff" blur={20} backgroundOpacity={0.75} />
									)}
								</Flex>
							)}
						</>
					)}

					<Flex gap="xs">
						{/* <MRT_GlobalFilterTextInput table={table} /> */}
						<MRT_ToggleFiltersButton table={table} />
						{/* <MRT_ToggleDensePaddingButton table={table} /> */}
					</Flex>
				</Flex>
			);
		},
		renderBottomToolbar: ({ table }) => {
			return (
				<Grid>
					<Grid.Col span={6}>
						<Flex gap="xs" align={"center"} justify={"start"} ml={20} h="100%">
							{apiData?.length > 0 && (
								<Card h={40} p={10}>
									<Flex gap="xs" justify={"center"} align={"center"}>
										<Text c={"teal.6"}>
											{config.translate.showing?.[lang]}{" "}
											{pagination?.pageIndex * pagination?.pageSize + 1} -{" "}
											{pagination?.pageIndex * pagination?.pageSize +
												apiData?.length}{" "}
										</Text>
										<Text c={"cyan.6"}>
											{config.translate.total?.[lang]} {CollectionCount}
										</Text>
									</Flex>
								</Card>
							)}
						</Flex>
					</Grid.Col>
					<Grid.Col span={6}>
						<MRT_BottomToolbar table={table} />
					</Grid.Col>
				</Grid>
			);
		},
	});

	useEffect(() => {
		NProgress.start();
		(async () => {
			let rawData;
			if (GlobalFilter) {
				try {
					rawData = await API.get(
						route,
						query
							? {
									...query,
									temporary: { $ne: true },
									// $or: [
									//   ...stringSearchFields.map((field) => ({
									//     [field]: new RegExp("^" + GlobalFilter, "i"),
									//   })),
									//   ...numberSearchFields.map((field) => ({
									//     $where: `/^${GlobalFilter}.*/.test(this.${field})`,
									//   })),
									// ],
								}
							: { temporary: { $ne: true } },
						sorting ? sorting : -1,
						uploadType ? uploadType : "",
						pagination?.pageSize ? pagination?.pageSize : limit ? limit : "",
						populate ? populate : "",
						pagination?.pageIndex * pagination?.pageSize,
						true
					);
				} catch (error) {
					setIsError(true);
					console.error(error);
					return;
				}
			} else if (ColumnFilters.length > 0) {
				try {
					rawData = await API.get(
						route,
						{
							$and: [
								{ ...query, temporary: { $ne: true } },
								...ColumnFilters.map(({ id, value }) => {
									let sendKey,
										sendValue = value;
									let customFilter = "";
									for (const [key, value] of Object.entries(config.translate)) {
										if (id === key) {
											sendKey = key;
										} else if (value?.[lang] === id) {
											if (key === "product") {
												customFilter = "product";
											} else if (key === "client") {
												customFilter = "client";
											} else if (key === "order") {
												customFilter = "order";
											} else {
												sendKey = key + "." + lang;
											}
										}
									}
									if (customFilter === "product") {
										return {
											product: sendValue,
										};
									} else if (customFilter === "order") {
										return {
											order: sendValue,
										};
									} else if (customFilter === "client") {
										return {
											client: sendValue,
										};
									}

									if (sendKey === "dateOfBirth") {
										return {
											[sendKey]: convertToGMT0(sendValue),
										};
									} else if (sendKey === "age") {
										if (sendValue[0] === "" && sendValue[1] === "")
											return { [sendKey]: { $gte: 1 } };
										if (sendValue[0] === "" && sendValue[1] === "") {
											return {
												dateOfBirth: {
													$gte: calculateBirthdate(1),
												},
											};
										}
										const dateFilter = {};
										if (sendValue[0] !== "") {
											dateFilter.$lte = calculateBirthdate(sendValue[0]);
										}
										if (sendValue[1] !== "") {
											dateFilter.$gte = calculateBirthdate(sendValue[1]);
										}
										return { dateOfBirth: dateFilter };
									} else if (sendKey === "index") {
										return {
											[sendKey]: sendValue,
										};
									} else if (sendKey === "date" || sendKey === "date-range") {
										if (Array.isArray(sendValue)) {
											let gte = sendValue[0];
											let lte = sendValue[1];
											let customSendValue = {};

											if (gte) {
												customSendValue.$gte = gte;
											}
											if (lte) {
												customSendValue.$lte = lte;
											}

											if (Object.keys(customSendValue).length === 0) {
												return { [sendKey]: { $gte: 1 } };
											} else {
												return {
													[sendKey]: customSendValue,
												};
											}
										} else {
											return { [sendKey]: { $gte: sendValue } };
										}
									} else if (id === "ID" || id === "taxCode") {
										return {
											[id]: Number(sendValue),
										};
									} else if (sendKey === "position" || sendKey === "department") {
										return {
											[sendKey]: sendValue,
										};
									} else if (
										sendKey === "" ||
										sendKey === "weight" ||
										sendKey === "paid" ||
										sendKey === "debt" ||
										sendKey === "quantity"
									) {
										let gte = sendValue[0];
										let lte = sendValue[1];
										let customSendValue = {};

										if (gte) {
											customSendValue.$gte = gte;
										}
										if (lte) {
											customSendValue.$lte = lte;
										}

										if (Object.keys(customSendValue).length === 0) {
											return { [sendKey]: { $gte: 1 } };
										} else {
											return {
												[sendKey]: customSendValue,
											};
										}
									} else {
										return {
											[sendKey]: {
												$regex: sendValue,
												$options: "i",
											},
										};
									}
								}),
							],
						},
						sorting ? sorting : -1,
						uploadType ? uploadType : "",
						pagination?.pageSize ? pagination?.pageSize : limit ? limit : "",
						populate ? populate : "",
						pagination?.pageIndex * pagination?.pageSize,
						true
					);
				} catch (error) {
					setIsError(true);
					console.error(error);
					return;
				}
			} else {
				try {
					rawData = await API.get(
						route,
						query
							? { ...query, temporary: { $ne: true } }
							: { temporary: { $ne: true } },
						sorting ? sorting : -1,
						uploadType ? uploadType : "",
						pagination?.pageSize ? pagination?.pageSize : limit ? limit : "",
						populate ? populate : "",
						pagination?.pageIndex * pagination?.pageSize,
						true
					);
				} catch (error) {
					setIsError(true);
					console.error(error);
					return;
				}
			}

			if (header) {
				const totals = {
					price: 0,
					paid: 0,
					debt: 0,
					difference: 0,
				};

				rawData?.items?.forEach((item) => {
					totals.price += Math.round(item.price);
					totals.paid += Math.round(item.paid);
					totals.debt += Math.round(item.debt);
					totals.difference += Math.round(totals.price - totals.paid - totals.debt);
				});

				setLocalHeader([
					{
						title: config.translate.price[lang],
						value: totals.price,
						color: "blue.6",
					},
					{
						title: config.translate.paid[lang],
						value: totals.paid,
						color: "teal.6",
					},
					{
						title: config.translate.debt[lang],
						value: totals.debt,
						color: "red.6",
					},
					{
						title: config.translate.difference[lang],
						value: totals.difference,
						color: "gray.6",
					},
				]);
			}

			if (customFiltersArrays) {
				if (customFiltersArrays.products) {
					rawData.items?.forEach((item) => {
						customFiltersArrays.products.forEach((product) => {
							if (item.product === product._id) {
								item.product = product;
							}
						});
					});
				}
				if (customFiltersArrays.clients) {
					rawData.items?.forEach((item) => {
						customFiltersArrays.clients.forEach((client) => {
							if (item.client === client._id) {
								item.client = client;
							}
						});
					});
				}
			}

			setApiData(rawData.items);
			setCollectionCount(rawData.count);

			NProgress.done();
		})();
		FirstRender = true;
		// eslint-disable-next-line
	}, [
		location.search,
		window.location.pathname,
		GlobalFilter,
		ColumnFilters,
		ColumnFilters.length,
		isBusy,
	]);

	useEffect(() => {
		const newUrl = `${location.pathname}?pageIndex=${pagination.pageIndex}&pageSize=${pagination.pageSize}`;
		history.push(newUrl);
		// eslint-disable-next-line
	}, [pagination?.pageIndex, pagination?.pageSize]);

	function ChartTooltip({ label, payload }) {
		if (!payload) return null;

		return (
			<Paper px="md" py="sm" withBorder shadow="md" radius="md">
				<Text fw={500} mb={5}>
					{/* if label is date then moment it */}
					{moment(label).format("MMM D, YYYY")}
				</Text>
				{payload.map((item) => (
					<Text key={item.name} c={item.color} fz="sm">
						{config.translate?.[item.name]?.[lang]}: {item.value}
					</Text>
				))}
			</Paper>
		);
	}

	if (NProgress.done()) {
		return (
			<div style={{ height: "calc(100vh - 220px)" }} className="wrapper">
				<MantineReactTable table={table} />
				{clientAnalytics && apiData && apiData.length > 0 && (
					<BarChart
						h={300}
						data={apiData}
						dataKey="date"
						withBarValueLabel
						valueFormatter={(value) => new Intl.NumberFormat("en-US").format(value)}
						tooltipProps={{
							content: ({ label, payload }) => {
								return <ChartTooltip label={label} payload={payload} />;
							},
						}}
						series={[
							{ name: "price", color: "blue.6" },
							{ name: "paid", color: "teal.6" },
							{ name: "debt", color: "red.6" },
						]}
						tickLine="y"
					/>
				)}
			</div>
		);
	} else return <ErrorPage />;
}
